/* You can add global styles to this file, and also import other style files */
html { 
    height: 100%; 
  }
  
  body { 
    height: 100%;
    margin: 0; 
    font-family: 'Roboto', sans-serif; 
    min-height: 100vh;
  }
  
  .loadertango {
    width: 150px;
      position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }
  
  $count: 100;
  $time: 2; //in seconds
  $size: 225;
  $color: #e91e63;
  
  .holder {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: #2D2F48;
  }
  
  .preloader {
    /* size */
    width: $size + px;
    height: $size + px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: rotatePreloader $time + s infinite ease-in;
  }
  @keyframes rotatePreloader {
    0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg);
    }
    100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg);
    }
  }
  .preloader div {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .preloader div:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0%;
    width: 30%;
    height: 1%;
    background-color: $color;
    transform: translateX(100%);
    border-radius: 100%;
  }
  
  @for $i from 1 through $count {
    .preloader div:nth-child(#{$i}) {
      transform: rotateZ(((360 / $count) * ($i - 1)) + deg);
      animation: rotateCircle + $i $time + s infinite linear;
      z-index: $count - $i;
    }
    @keyframes rotateCircle#{$i} {
      #{percentage(((50 / $count) * ($i - 1)) / 100)} {
        opacity: 0;
      }
      #{percentage((((50 / $count) + 0.0001) * ($i - 1)) / 100)} {
        opacity: 1;
        transform: rotateZ((0 - ((360 / $count) * ($i - 2))) + deg);
      }
      #{percentage((((50 / $count) * ($i - 0)) + 2) / 100)} {
        transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
      }
      #{percentage(((50 + ((50 / $count) * ($i - 0))) + 2) / 100)} {
        transform: rotateZ((0 - ((360 / $count) * ($i - 1))) + deg);
      }
      100% {
        transform: rotateZ((0 - ((360 / $count) * ($count - 1))) + deg);
        opacity: 1;
      }
    }
  }

  .float{
    position:fixed;
    width:40px;
    height:40px;
    bottom:50px;
    left:35px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    box-shadow: 2px 2px 3px #999;
    z-index:100;
  }
  .float:hover {
    text-decoration: none;
    color: #25d366;
    background-color:#fff;
  }
  
  .my-float{
    margin-top:16px;
  }